import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Trainer from "../components/Trainer"

const IndexPage = props => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "bg-gray-100" }} />
      <div className="p-4 grid grid-cols-1 gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
        {props.data.strapi.petTrainers.map(t => (
          <Trainer key={t.id} trainer={t} />
        ))}
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    strapi {
      petTrainers(sort: "display_order:asc") {
        id
        name
        location
        pets {
          id
          name
          info
        }
        actions {
          id
          action
          note
        }
      }
    }
  }
`

export default IndexPage
