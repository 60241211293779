import React, { useState } from "react"

export default ({ trainer }) => {
  const [showActions, setShowActions] = useState(false)

  return (
    <div className="mb-3">
      <div className="rounded shadow-md bg-white p-4">
        <h2 className="font-bold text-md">{trainer.name}</h2>
        <p className="text-gray-700">{trainer.location}</p>
        <h3 className="text-blue-600 font-bold mb-1 mt-2">Pets</h3>
        {trainer.pets.map(a => (
          <p className="text-gray-700 text-base ml-2" key={a.id}>
            <span className="font-bold">{a.name}</span>: {a.info}
          </p>
        ))}
        <h3 className="text-blue-600 font-bold mb-1 mt-4">Actions</h3>
        {showActions ? (
          <>
            {trainer.actions.map(a => (
              <p className="text-gray-700 text-base ml-2">
                <span className="font-bold text-base">{a.action}</span>{" "}
                {a.note ? <span className="italic">({a.note})</span> : ""}
              </p>
            ))}

            <button
              className="text-white text-sm ml-2 mt-2 bg-blue-600 rounded px-2 py-1"
              onClick={() => setShowActions(false)}
            >
              Hide actions
            </button>
          </>
        ) : (
          <button
            className="text-white text-sm ml-2 bg-blue-600 rounded px-2 py-1"
            onClick={() => setShowActions(true)}
          >
            Show actions
          </button>
        )}
      </div>
    </div>
  )
}
